<template>
<Note :widget="widget" :data="data" :editable="true" @reloadNotes="handleNotes" :key="componentKey" />
</template>

<script>
// این ویو برای ماژول یاداشت و. اسناد در داشبورد مورد استفاده قرار میگیرد
import Note from '@/components/Mobile/Note.vue'

export default {
    name: 'NotesMobileView',
    components: {
        Note,
    },
    data: function () {
        return {
            widget: {},
            data: {},
            componentKey: 0,
        }
    },
    methods: {
        init() {
            this.$helpers.getWidgets({ widget_type: 'note', store: false }).then((widgets) => {
                let widget = widgets[0];

                this.widget = widget.widget;
                this.data = widget.data;
                this.componentKey += 1;
            });
        },
        handleNotes() {
            this.init();
        },
    },
    mounted() {
        // در متد مانت یک فانکشی رو از هلپر کال میکنیم جهت لود کردن داده های ویجت 
        this.init();
    },
}
</script>
